/* Bootstrap overrides
-------------------------------------------------- */

$body: #000;
$headings-font-weight: 300 !important;
$primary: rgba(19, 60, 85, .96);
// $primary: rgba(29, 34, 42, .85);
$light: #FFFFFF90;
$card-border-radius: 0.8rem;
$card-inner-border-radius: subtract($card-border-radius, 1px);

$headings-font-weight: 300 !important;
$a: #1392ff;

$secondary: rgba(35, 105, 154, .9);

$bg-gradient: linear-gradient(to right, rgb(26 62 92 / 75%) 0%, rgb(38 75 108 / 85%) 51%, rgb(27 61 90 / 94%) 100%);
// $bg-gradient: linear-gradient(to right, rgba(19, 60, 85, .8) 0%, rgba(15, 72, 108, 0.92) 51%, rgba(19, 60, 85, .96) 100%);
$bg-gradient-secondary: linear-gradient(to right, rgba(53, 118, 164, 0.7) 0%, rgba(35, 105, 154, .9) 51%, rgba(43, 99, 139, 0.9) 100%);

// gris clair: rgb(174, 174, 174);
// vert: rgba(19, 85, 43, 0.9);
// bleu foncé: rgba(19, 60, 85, .9);
// orange vif: #ff7923;
// orange: background: rgba(115, 50, 28, 0.9);
// noir: background: rgba(29, 34, 42, .85);

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';

.custom-classname.btn-primary {
  background-color: green;
}

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

body {
  background-image: url('/assets/img/background SAC-SALV.webp');
  background-size: cover;
  // background-position: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #090B0F;
  height: 100vh;
  // padding: 0;
}

@include media-breakpoint-up(lg) {
  body {
    background-position: 50%;
  }
}

// body {
//   font-family: 'Poppins,sans-serif';
//   color: #FFFFFF;
//   background: #FFFFFF;
//   transition-property: background-color;
//   transition-duration: 200ms;
//   line-height: 1.5;
//   min-height: 100vh;
//   overflow-x: hidden;
//   z-index: -2;
//   background-color: rgba(0,0,0,0.4);
// }

#root {
  // background-color: rgba(0,0,0,0.4);
  color: $white !important;
}

.container-fluid {
  flex-wrap: wrap !important;
}

.text-no-break {
  text-wrap: nowrap;
  white-space: nowrap;
  // overflow: hidden;
  // display: block;
  // text-overflow: ellipsis;
}

.text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.title {
  color: #133c55;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

.gap-sm {
  gap: .4rem;
}

.gap-md {
  gap: .8rem;
}

.mt-10 {
  margin-top: 8rem;
}

.text-muted {
  color: #FFFFFFC0 !important;
}

.col {
  margin-bottom: 1.25rem;
  padding-right: 10px;
  padding-left: 10px;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.link-underline {
  text-decoration: underline;
}

/* Navbar
-------------------------------------------------- */
.navbar .container {
  padding: 0px 20px;
}

.info-message {
  background: #f1bb11;
  // background: rgba(253, 165, 5, 0.9);
  color: #1e1e1e;
  text-shadow: #00000040 0px 0px 4px;
  // background: rgb(255 255 255 / 80%);
  // color: #000000C0;
  text-align: center;
  padding: .45rem .8rem .5rem;
  font-weight: 600;
  // border-radius: 0 0 $card-border-radius $card-border-radius;
  box-shadow: 0 0 10px #eeeeee60;
  font-size: 20px;
}

.navbar-nav {
  gap: .8rem;
}

.multiversx-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  font-size: 2.4rem;
  // border-left: 1px solid $gray-400;
  // padding-left: 3px;
  // margin-left: 4px;
}

.nav-link,
.btn {
  color: $white !important;
}

.nav-link {
  border: solid 2px rgb(17 53 75);
  border-radius: $card-border-radius;
  background: $primary;
  padding: 0.7rem 1.2rem !important;
  text-shadow: #000000C0 1px 0px 10px;
  width: 130px;
}

.nav-bj {
  background-image: url('/assets/img/bj.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $secondary;

  display: flex;
  flex-direction: column;
  min-height: 95px;
  justify-content: space-between;
}

.nav-roulette {
  background-image: url('/assets/img/roulette.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  min-height: 95px;
  justify-content: space-between;
  // color: $primary !important;
}

.picto {
  background: #1e1e1e;
  padding: 0.45rem;
  border-radius: 0.65rem;
  display: flex;
}

/* Dashboard
-------------------------------------------------- */

.card-body {
  min-width: 180px;
}

@include media-breakpoint-up(md) {
  .card-body {
    min-width: 200px;
  }
}

@include media-breakpoint-up(lg) {
  .card-body {
    min-width: unset;
  }
}

.input-dates {
  min-height: 34px;
  min-width: 84px;
  display: flex;
}

.loader-percent {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 52px;
  padding-right: 30px;
  font-weight: bold;
  font-style: italic;
}

.chart-container {
  height: 250px;
}

.games-graph-select {
  font-size: 1rem;
  // border-radius: 20px !important;
  padding-right: 10px !important;
  position: absolute;
  top: 9px;
  right: 21px;
}

.stats-plus {
  color: #FFFFFF60 !important;
  border: solid 2px #FFFFFF60;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-weight: bolder;
  transition: .4s;
}

.stats-plus:hover {
  color: #FFFFFF !important;
  border-color: #FFFFFF;
}

.card.orange-metal {
  background-color: #ff7923B0 !important;
  // background-image: linear-gradient(115deg, rgb(85 55 19 / 80%) 0%, #ff7923D0 51%, rgb(85 56 19 / 96%) 100%);
  background-image: linear-gradient(115deg, rgb(116 78 32 / 70%) 0%, rgb(255 121 35 / 80%) 51%, rgb(137 85 19 / 96%) 100%);
}

.card.blue-metal {
  background-color: #2175c9d0 !important;
  background-image: linear-gradient(115deg, rgba(19, 60, 85, 0.8) 0%, #2175c9 51%, rgba(19, 60, 85, 0.96) 100%);
}

.dashboard-stats .card {
  height: 100%;
}

.period-selector {
  gap: 0.5rem;
  // border-radius: 0.6rem;
  padding: 6px 8px;
  // box-shadow: 0 0 10px #eeeeee50;
  transition: .5s;
}

.btn.bg-gradient.btn-active,
.btn.bg-gradient.btn-active:hover {
  border-color: #FFFFFFB0;
}

input.btn {
  padding-top: 0.28rem;
  padding-bottom: 0.325rem;
}

select.bg-gradient:focus {
  outline: 0;
}

select.bg-gradient option {
  // color: $primary !important;
  background: $primary !important;
}

select.bg-gradient {
  color: #FFFFFF !important;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.25rem;
  border-radius: 0.25rem;
}

select.bg-gradient,
.btn.bg-gradient {
  background-size: 200% auto;
  box-shadow: unset;
  // text-transform: uppercase;
  transition: .5s;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-color: $secondary;
  border-width: 1.5px;
}

select.bg-gradient:hover,
.btn.bg-gradient:hover {
  background-position: right center;
  background-color: unset;
  border-color: $primary;
}

.logo-bj {
  background-image: url('/assets/img/bj.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 3rem;
  height: 2rem;
  border-radius: 0.45rem;
  background-color: $secondary;
  border: solid 0.6px #ffffff90;
}

.logo-roulette {
  background-image: url('/assets/img/roulette.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 3rem;
  height: 2rem;
  border-radius: 0.45rem;
  border: solid 0.6px #ffffff90;
}

.stat-value,
.stat-value-egld,
.stat-value-payout {
  background-color: #f1bb11;
  color: #000 !important;
  padding: 4px;
  border-radius: 10rem;
  font-size: 0.75rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-weight: 700;
}

// .stat-value-egld {
//   background-color: #27f9df;
// }

// .stat-value-payout {
//   background-color: #f6f927;
// }

.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.block {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: $card-border-radius;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0.5rem;
  width: 70%;
}

.dapp-core-component__main__card {
  color: white !important;
}

.dapp-core-component__transactionsTable-styles__transaction-cell {
  color: $white !important;
  min-height: 1.5rem;
  margin: .5rem !important;
}

.bg-gradient {
  background-image: $bg-gradient;
  box-shadow: 0 0 10px #eeeeee20;
}

// .btn.bg-gradient {
//   background-image: linear-gradient(to right, #5C258D 0%, #4389A2  51%, #5C258D  100%);
//   margin: 10px;
//   padding: 15px 45px;
//   text-align: center;
//   text-transform: uppercase;
//   transition: 0.5s;
//   background-size: 200% auto;
//   color: white;            
//   box-shadow: 0 0 20px #eee;
//   border-radius: 10px;
//   display: block;
// }

// .btn.bg-gradient:hover {
//   background-position: right center; /* change the direction of the change here */
//   color: #fff;
//   text-decoration: none;
// }

.card {
  // background: rgba(35, 105, 154, 0.9) !important;
  background-image: $bg-gradient;
  background-color: unset !important;
  // background-color: #0f2822E0 !important;
  border-radius: $card-border-radius;
  // background-size: 200% auto;
}

// .shadow-sm {
//   box-shadow: 0 0 20px #eeeeee60 !important;
// }

.dashboard-table-title {
  font-weight: bolder;
  font-size: 1.8rem;
  padding: 6px 10px;

  // @include media-breakpoint-up(md) {
  //   padding: 6px 60px;
  // }

  // padding: 2px 40px 4px;
  // border-radius: 0.6rem;
  // margin: 0.6rem;
  // background: rgba(35, 105, 154, 0.7);
  // background-image: linear-gradient(to right, rgba(53, 118, 164, 0.7) 0%, rgba(35, 105, 154, .9) 51%, rgba(43, 99, 139, 0.9)  100%);;
}

.table-col {
  // border: solid 1px #00000030;
  // border-radius: 1.2rem !important;
  // margin: 2px 5px 5px;
  // margin: 3px 0 2px;
  // padding: 5px;
  // background-color: #00000030 !important;
  text-align: center;
  font-weight: bold;
  color: #FFFFFFD0;
  text-transform: uppercase;
}

.dapp-core-component__transactionsTable-styles__table th:first-child,
.dapp-core-component__transactionsTable-styles__table td:first-child {
  padding-left: 5px !important;
}

.dapp-core-component__transactionsTable-styles__transactions-table {
  border-radius: $card-border-radius !important;
}

.dapp-core-component__transactionsTable-styles__table th {
  background-color: unset !important;
  // background-color: $primary !important;
}

.dapp-core-component__transactionsTable-styles__table th:first-child {
  border-radius: $card-border-radius 0 0 0 !important;
}

.dapp-core-component__transactionsTable-styles__table th:last-child {
  border-radius: 0 $card-border-radius 0 0 !important;
}

.dapp-core-component__transactionsTable-styles__table tr {
  border-top: 0 !important;
}

.dapp-core-component__main__badge-secondary {
  background-color: #6C757D !important;
}

.badge-win {
  background-color: #20A23D !important;
}

.badge-blackjack {
  background-color: #f1bb11 !important;
}

.dapp-core-component__main__text-white {
  color: #000 !important;
}

/* Sign Message
-------------------------------------------------- */
.sign-message {

  & h1 {
    font-size: 24px;
  }

  & textarea {
    min-height: 100px;
  }

  & button {
    align-self: center;
  }
}

.textarea-with-copy {
  position: relative;

  & a {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  @include media-breakpoint-up(md) {
    min-width: 650px;
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

.dapp-loader {
  background-image: $bg-gradient;
  // background: $primary !important;
  border-radius: $card-border-radius;
  padding-bottom: 1.1rem !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: .5s;
  // background-color: #ff7923F0 !important;
  // background-image: linear-gradient(115deg, rgb(85 55 19 / 80%) 0%, #ff7923D0 51%, rgb(85 56 19 / 96%) 100%);
}

.dapp-loader,
.dapp-core-component__main__text-primary.fa-spin {
  color: #FFFFFFD8 !important;
}

// .dapp-loader,
// .dapp-core-component__main__text-primary.fa-spin {
//   color: $primary !important;
// }

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;
    font-size: 14px;

    a {
      color: $black;

      &:hover {
        color: $black;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}